import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import BackgroundImage from '../../components/BackgroundImage';
import TextAndImage from '../../components/TextAndImage';
import { ImageOverlay } from '../../utils';
import { HTMLContent } from '../../components/Content';
import useSiteMetadata from '../../components/SiteMetadata';
import MetaComponent from '../../components/MetaComponent';

export const LongStayPageTemplate = ({
  image,
  title,
  helmet,
  blocks,
  content,
}) => {
  const renderBlocks = (blocks) =>
    blocks.map((block) => <TextAndImage block={block} />);
  return (
    <div className='page-container container content'>
      {helmet || ''}
      <BackgroundImage classes={'page-header-image'} imageInfo={image.image}>
        <h1 className='title page-title'>
          <hr />
          {title}
        </h1>
        <ImageOverlay overlay={image.overlay} />
      </BackgroundImage>

      <div className='container content'>
        {renderBlocks(blocks)}
        <div className='columns'>
          <div className='column is-1' />
          <div className='column is-10'>
            <HTMLContent content={content} />
          </div>
          <div className='column is-1' />
        </div>
      </div>
    </div>
  );
};

const LongStayPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const { title, siteUrl } = useSiteMetadata();
  return (
    <Layout>
      <LongStayPageTemplate
        location={location}
        title={frontmatter.title}
        image={frontmatter.heroImageObject}
        blocks={frontmatter.blocks}
        content={frontmatter.content}
        helmet={
          <MetaComponent
            title={`${frontmatter.seoObject.metaTitle}`}
            canonical={`${siteUrl}${location.pathname}`}
            siteName={`${title}`}
            description={`${frontmatter.seoObject.metaDescription}`}
            link={`${siteUrl}${location.pathname}`}
            image={`${frontmatter.heroImageObject.image}`}
          />
        }
      />
    </Layout>
  );
};

export default LongStayPage;

export const pageQuery = graphql`
  query longStayPageQueryJP {
    markdownRemark(frontmatter: { templateKey: { eq: "long-stay-page-jp" } }) {
      frontmatter {
        title
        heroImageObject {
          overlay
          image
        }
        blocks {
          image
          image_position
          content
          isCta
          buttonText
          linkObject {
            isExternal
            link
          }
        }
        content
        seoObject {
          metaTitle
          metaDescription
        }
      }
    }
  }
`;
